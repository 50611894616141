import { defineStore } from "pinia";
// import { ref, toRefs } from "vue";

export const useWalletStore = defineStore(
  "wallet",
  () => {
    const pencairanDana = ref<{
      jumlah_pencairan: string;
      biaya_layanan: number;
      total: number;
    }>({
      jumlah_pencairan: "",
      biaya_layanan: "",
      total: 0,
    });

    function set(params: any) {
      const myStore = useWalletStore();
      const states: any = toRefs(myStore.$state);
      const keys = Object.keys(params);

      keys.forEach((key) => {
        states[key].value = params[key];
      });
    }

    return {
      pencairanDana,
      set,
    };
  },
  {
    persist: true,
  },
);
